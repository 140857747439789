
import {computed, defineComponent, ref} from "vue";
import {
  IonCol,
  IonLabel,
  IonRow,
  IonItem, IonGrid, isPlatform,
} from "@ionic/vue";


export default defineComponent({
  name: "CheckCreateEntryDeliveryItemComponent",
  components: {
    IonRow,
    IonLabel,
    IonCol,
    IonItem,
    IonGrid
  },
  props: {
    entryDelivery: null,
  },
  setup(props: any) {
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const CommandItem = ref(props.entryDelivery);
    const id = CommandItem.value.id;
    const items = CommandItem.value.items;

    return {
      isTablet,
      CommandItem,
      id,
      items,
    };
  }
})
